<template>
  <vue-good-table
    :rows="rows"
    :columns="columns"
    class="compact-good-table analytics-mentors-table"
  >
    <div slot="emptystate">Нет данных</div>
    <template slot="table-row" slot-scope="props">
      <div
        v-if="props.column.field == 'mentor'"
        class="d-flex flex-column p-25"
      >
        <b-media
          v-if="props.row.mentor"
          vertical-align="center"
          class="d-flex align-items-center"
        >
          <template #aside>
            <b-avatar :src="props.row.mentor.avatar" />
          </template>
          <span>
            {{ props.row.mentor.fullName }}
          </span>
        </b-media>
      </div>

      <div v-else class="d-flex align-items-center justify-content-center">
        <table
          class="w-100 h-100 inner-table"
          :style="`min-width: calc(${
            props.row[props.column.field].length
          } * 100px)`"
        >
          <tr>
            <td
              v-for="(value, index) in props.row[props.column.field]"
              :key="index"
              class="inner-table__cell overflow-hidden"
            >
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  text-nowrap
                  p-25
                "
              >
                <span
                  v-if="
                    (props.column.field === 'answerTime' ||
                      props.column.field === 'approveTime') &&
                    props.row.mentor
                  "
                >
                  {{ formatTime(value) }}
                </span>
                <span v-else>
                  {{ value }}
                </span>
                <span v-if="index !== 0 && props.row.mentor">
                  <span v-if="props.row[props.column.field][index - 1] < value">
                    <feather-icon
                      class="text-success"
                      size="20"
                      icon="ArrowUpIcon"
                    />
                  </span>
                  <span v-if="props.row[props.column.field][index - 1] > value">
                    <feather-icon
                      class="text-danger"
                      size="20"
                      icon="ArrowDownIcon"
                    />
                  </span>
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { BMedia, BAvatar } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import { formatTime } from '@/helpers/analytics'

export default {
  components: {
    VueGoodTable,
    BMedia,
    BAvatar,
  },
  data: () => ({
    columns: [
      { field: 'mentor', label: 'Наставник', sortable: false },
      {
        field: 'studentsCount',
        label: 'Кол-во студентов',
        sortable: false,
        tdClass: 'gray-cell',
        thClass: 'gray-cell',
      },
      {
        field: 'homeworksApproved',
        label: 'ДЗ принято',
        sortable: false,
      },
      {
        field: 'homeworksRejected',
        label: 'ДЗ отклонено',
        sortable: false,
        tdClass: 'gray-cell',
        thClass: 'gray-cell',
      },
      {
        field: 'successfulTests',
        label: 'Тестов сдано',
        sortable: false,
      },
      {
        field: 'videoReviews',
        label: 'Видеоразборов',
        sortable: false,
        tdClass: 'gray-cell',
        thClass: 'gray-cell',
      },
      {
        field: 'averageRating',
        label: 'Средняя оценка',
        sortable: false,
      },
      {
        field: 'answerTime',
        label: '~Время ответа',
        sortable: false,
        tdClass: 'gray-cell',
        thClass: 'gray-cell',
      },
      {
        field: 'approveTime',
        label: '~Время принятия дз',
        sortable: false,
      },
      {
        field: 'averageRejected',
        label: '~Правок',
        sortable: false,
        tdClass: 'gray-cell',
        thClass: 'gray-cell',
      },
      {
        field: 'studentMessagesCount',
        label: 'Сообщений студентов',
        sortable: false,
      },
      {
        field: 'mentorMessagesCount',
        label: 'Ответов наставников',
        sortable: false,
        tdClass: 'gray-cell',
        thClass: 'gray-cell',
      },
    ],
  }),
  computed: {
    ...mapGetters({ rows: 'analytics/mentorsTable' }),
  },
  methods: { formatTime },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.compact-good-table.analytics-mentors-table {
  td {
    padding: 0 !important;
  }

  th {
    padding: 5px !important;
  }

  .gray-cell {
    background-color: aliceblue;
  }
}

.dark-layout {
  .compact-good-table.analytics-mentors-table {
    .gray-cell {
      background-color: #161d31 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.inner-table {
  table-layout: fixed;

  tr {
    background: none !important;
  }

  &__cell {
    background: none !important;
    border-bottom: none !important;
    border-right: none !important;
  }

  &__cell:not(.header-cell) {
    border-top: none !important;
  }

  &__cell:first-child {
    border-left: none !important;
  }
}
</style>
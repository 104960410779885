var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{staticClass:"compact-good-table analytics-common-table",attrs:{"rows":_vm.rows,"columns":_vm.columns},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'title')?_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")]):_c('div',{staticClass:"\n        dark-body-color\n        cursor-pointer\n        h-100\n        w-100\n        d-flex\n        align-items-center\n        p-25\n      ",on:{"click":function($event){return _vm.showChart(props.column.field)}}},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'title')?_c('div',{staticClass:"dark-body-color d-flex flex-column p-25"},[_c('div',[_vm._v(_vm._s(props.row.title))]),_c('div',{staticClass:"text-nowrap small"},[_vm._v(" "+_vm._s(new Date(props.row.dateStart).toLocaleString('ru', { year: '2-digit', month: '2-digit', day: '2-digit', }))+"- "+_vm._s(new Date(props.row.dateEnd).toLocaleString('ru', { year: '2-digit', month: '2-digit', day: '2-digit', }))+" ")])]):_c('div',{staticClass:"\n        common-cell\n        d-flex\n        align-items-center\n        justify-content-center\n        cursor-pointer\n      ",class:{
        selected: _vm.series.some(
          function (s) { return s.streamId === props.row.id && s.column === props.column.field; }
        ),
      },on:{"click":function($event){return _vm.showChart(props.column.field, props.row.id, props.row.title)}}},[(
          props.column.field === 'answerTime' ||
          props.column.field === 'approveTime'
        )?_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(props.formattedRow[props.column.field]))+" ")]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))]),(
          _vm.series.some(
            function (s) { return s.streamId === props.row.id && s.column === props.column.field; }
          )
        )?_c('span',{staticClass:"ml-25"},[_c('div',{staticClass:"analytics-marker",style:(("background-color:" + (_vm.colors[
              _vm.series.findIndex(
                function (s) { return s.streamId === props.row.id &&
                  s.column === props.column.field; }
              )
            ])))})]):_vm._e()])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("Нет данных")])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <vue-good-table
    :rows="rows"
    :columns="columns"
    class="compact-good-table analytics-common-table"
  >
    <div slot="emptystate">Нет данных</div>
    <template slot="table-column" slot-scope="props">
      <span v-if="props.column.field === 'title'">
        {{ props.column.label }}
      </span>
      <div
        v-else
        class="
          dark-body-color
          cursor-pointer
          h-100
          w-100
          d-flex
          align-items-center
          p-25
        "
        @click="showChart(props.column.field)"
      >
        {{ props.column.label }}
      </div>
    </template>
    <template slot="table-row" slot-scope="props">
      <div
        v-if="props.column.field == 'title'"
        class="dark-body-color d-flex flex-column p-25"
      >
        <div>{{ props.row.title }}</div>
        <div class="text-nowrap small">
          {{
            new Date(props.row.dateStart).toLocaleString('ru', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })
          }}-
          {{
            new Date(props.row.dateEnd).toLocaleString('ru', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })
          }}
        </div>
      </div>

      <div
        v-else
        class="
          common-cell
          d-flex
          align-items-center
          justify-content-center
          cursor-pointer
        "
        :class="{
          selected: series.some(
            s => s.streamId === props.row.id && s.column === props.column.field,
          ),
        }"
        @click="showChart(props.column.field, props.row.id, props.row.title)"
      >
        <span
          v-if="
            props.column.field === 'answerTime' ||
            props.column.field === 'approveTime'
          "
        >
          {{ formatTime(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        <span
          v-if="
            series.some(
              s =>
                s.streamId === props.row.id && s.column === props.column.field,
            )
          "
          class="ml-25"
        >
          <div
            class="analytics-marker"
            :style="`background-color:${
              colors[
                series.findIndex(
                  s =>
                    s.streamId === props.row.id &&
                    s.column === props.column.field,
                )
              ]
            }`"
          ></div>
        </span>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import { formatTime, colors } from '@/helpers/analytics'

export default {
  components: {
    VueGoodTable,
  },
  data: () => ({
    colors,
    columns: [
      { field: 'title', label: 'Поток', sortable: false },
      { field: 'studentsCount', label: 'Кол-во студентов', sortable: false },
      { field: 'homeworksApproved', label: 'ДЗ принято', sortable: false },
      { field: 'homeworksRejected', label: 'ДЗ отклонено', sortable: false },
      { field: 'successfulTests', label: 'Тестов сдано', sortable: false },
      { field: 'videoReviews', label: 'Видеоразборов', sortable: false },
      { field: 'averageRating', label: 'Средняя оценка', sortable: false },
      { field: 'answerTime', label: '~Время ответа', sortable: false },
      { field: 'approveTime', label: '~Время принятия дз', sortable: false },
      {
        field: 'studentMessagesCount',
        label: 'Сообщений студентов',
        sortable: false,
      },
      {
        field: 'mentorMessagesCount',
        label: 'Ответов наставников',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      rows: 'analytics/commonTable',
      series: 'analytics/chartSeries',
    }),
  },
  methods: {
    formatTime,
    async showChart(column, streamId, streamTitle) {
      if (!streamId) {
        for (const stream of this.rows) {
          await this.$store.dispatch('analytics/fetchChartData', {
            column,
            title: `${this.columns.find(c => c.field === column).label} (${
              stream.title
            })`,
            streamId: stream.id,
          })
        }

        return
      }

      await this.$store.dispatch('analytics/fetchChartData', {
        column,
        title: `${
          this.columns.find(c => c.field === column).label
        } (${streamTitle})`,
        streamId,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.compact-good-table.analytics-common-table {
  th,
  td {
    padding: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.analytics-marker {
  width: 10px;
  height: 10px;
  border: 1px solid $input-border-color;
  border-radius: 55px;
  background-color: red;
}

.common-cell {
  &.selected,
  &:hover {
    background: aliceblue;
  }
}

.dark-layout {
  .dark-body-color {
    color: $theme-dark-body-color !important;
  }

  .common-cell {
    &.selected,
    &:hover {
      background: #161d31 !important;
    }
  }
}
</style>
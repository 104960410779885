<template>
  <b-card no-body>
    <b-overlay :show="pending" rounded="sm">
      <Filters />
      <h2 class="ml-1 mt-1">Общая статистика</h2>
      <Common class="mb-2" />
      <Chart />
      <h2 class="ml-1 mt-1">Наставники</h2>
      <Mentors />
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import Filters from '@/components/analytics/Filters.vue'
import Common from '@/components/analytics/Common.vue'
import Chart from '@/components/analytics/Chart.vue'
import Mentors from '@/components/analytics/Mentors.vue'

export default {
  components: {
    BCard,
    BOverlay,
    Filters,
    Common,
    Chart,
    Mentors,
  },
  computed: {
    pending() {
      return this.$store.getters['analytics/pending']
    },
  },
  async mounted() {
    await this.$store.dispatch('analytics/fetchFilterData')
  },
}
</script>
<template>
  <b-row class="p-50">
    <b-col cols="6" lg="3" class="px-1 mb-1 mb-lg-0">
      <wayup-select
        v-model="selectedCourse"
        class="select"
        placeholder="выберите курс"
        emptyFilterMessage="нет подходящих курсов"
        :options="courses"
        label="title"
        @clear="updateFilter"
        @change="updateFilter"
      >
        <template #option="{ title, imageUrl, hex }">
          <div class="d-flex align-items-center course-option">
            <div
              class="
                image-wrapper
                d-flex
                align-items-center
                justify-content-center
                mr-50
              "
              :style="`background: ${hex}`"
            >
              <img :src="imageUrl" alt="" />
            </div>
            <div class="title">
              {{ title }}
            </div>
          </div>
        </template>
        <template #selected-option="{ title, imageUrl, hex }">
          <div class="d-flex align-items-center course-option selected">
            <div
              class="
                image-wrapper
                d-flex
                align-items-center
                justify-content-center
                mr-50
              "
              :style="`background: ${hex}`"
            >
              <img :src="imageUrl" alt="" />
            </div>
            <div class="title">
              {{ title }}
            </div>
          </div>
        </template>
      </wayup-select>
    </b-col>
    <b-col cols="6" lg="3" class="px-1">
      <wayup-select
        v-model="selectedStreams"
        class="select"
        placeholder="выберите потоки"
        emptyFilterMessage="нет подходящих потоков"
        :disabled="!selectedCourse"
        :multiselect="true"
        :selectedOnTop="true"
        :closeOnSelect="false"
        :elementDeclination="['поток', 'потока', 'потоков']"
        :options="streams"
        label="title"
        @change="updateFilter"
      >
        <template #option="{ title, dateStart }">
          <div class="d-flex align-items-center option">
            <div class="title">
              <div>
                {{ title }}
              </div>
              <div class="bottom-line">
                <feather-icon size="15" icon="CalendarIcon" />
                <small>
                  {{ dateStart }}
                </small>
              </div>
            </div>
          </div>
        </template>
      </wayup-select>
    </b-col>
    <b-col cols="12" lg="6" class="px-1 d-flex align-items-center">
      <b-form-datepicker
        v-model="dateStart"
        placeholder="начало периода"
        locale="ru-RU"
        hide-header
        start-weekday="1"
        :date-format-options="dateFormat"
        :state="dateError === true ? false : undefined"
        :max="new Date()"
        @input="updateFilter"
      />
      <span class="mx-50">-</span>
      <b-form-datepicker
        v-model="dateEnd"
        placeholder="конец периода"
        locale="ru-RU"
        hide-header
        start-weekday="1"
        :date-format-options="dateFormat"
        :state="dateError === true ? false : undefined"
        :max="new Date()"
        @input="updateFilter"
      />
      <b-dropdown right variant="flat-primary" class="ml-25">
        <template #button-content>
          <feather-icon icon="CalendarIcon" size="16" />
        </template>
        <b-dropdown-item @click="selectToday">Сегодня</b-dropdown-item>
        <b-dropdown-item @click="selectYestarday">Вчера</b-dropdown-item>
        <b-dropdown-item @click="selectWeek">Последние 7 дней</b-dropdown-item>
        <b-dropdown-item @click="selectMonth">
          Последние 30 дней
        </b-dropdown-item>
        <b-dropdown-item @click="selectThisMonth">Этот месяц</b-dropdown-item>
        <b-dropdown-item @click="selectPastMonth">
          Прошлый месяц
        </b-dropdown-item>
        <b-dropdown-item @click="selectQuarter">Этот квартал</b-dropdown-item>
        <b-dropdown-item @click="selectYear">Этот год</b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import WayupSelect from '@/components/WayupSelect.vue'
import {
  BRow,
  BCol,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    WayupSelect,
    BRow,
    BCol,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
  },
  data: () => ({
    dateFormat: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    },
  }),
  computed: {
    ...mapGetters({
      courses: 'analytics/courses',
      streams: 'analytics/streams',
      _selectedCourse: 'analytics/selectedCourse',
      _selectedStreams: 'analytics/selectedStreams',
      _dateStart: 'analytics/dateStart',
      _dateEnd: 'analytics/dateEnd',
      dateError: 'analytics/dateError',
    }),
    selectedCourse: {
      get() {
        return this._selectedCourse
      },
      set(value) {
        this.$store.commit('analytics/setCourseFilter', value)
      },
    },
    selectedStreams: {
      get() {
        return this._selectedStreams
      },
      set(value) {
        this.$store.commit('analytics/setStreamsFilter', value)
      },
    },
    dateStart: {
      get() {
        return this._dateStart
      },
      set(value) {
        this.$store.commit('analytics/setDateStart', value)
      },
    },
    dateEnd: {
      get() {
        return this._dateEnd
      },
      set(value) {
        this.$store.commit('analytics/setDateEnd', value)
      },
    },
  },
  methods: {
    async updateFilter() {
      await this.$store.dispatch('analytics/fetchData')
      this.$store.commit('analytics/clearChart')
    },
    formatDate(date) {
      const offset = date.getTimezoneOffset()
      date = new Date(date.getTime() - offset * 60 * 1000)
      return date.toISOString().split('T')[0]
    },
    selectToday() {
      this.dateStart = this.formatDate(new Date())
      this.dateEnd = this.formatDate(new Date())
      this.updateFilter()
    },
    selectYestarday() {
      const date = new Date()
      date.setDate(date.getDate() - 1)

      this.dateStart = this.formatDate(date)
      this.dateEnd = this.formatDate(date)
      this.updateFilter()
    },
    selectWeek() {
      const date = new Date()
      date.setDate(date.getDate() - 6)

      this.dateStart = this.formatDate(date)
      this.dateEnd = this.formatDate(new Date())
      this.updateFilter()
    },
    selectMonth() {
      const date = new Date()
      date.setDate(date.getDate() - 29)

      this.dateStart = this.formatDate(date)
      this.dateEnd = this.formatDate(new Date())
      this.updateFilter()
    },
    selectThisMonth() {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)

      this.dateStart = this.formatDate(firstDay)
      this.dateEnd = this.formatDate(new Date())
      this.updateFilter()
    },
    selectPastMonth() {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
      const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)

      this.dateStart = this.formatDate(firstDay)
      this.dateEnd = this.formatDate(lastDay)
      this.updateFilter()
    },
    selectYear() {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), 0, 1)

      this.dateStart = this.formatDate(firstDay)
      this.dateEnd = this.formatDate(new Date())
      this.updateFilter()
    },
    selectQuarter() {
      const date = new Date()
      const month = date.getMonth()
      let firstQuarterMonth = 0

      if (month > 2) {
        firstQuarterMonth = 3
      }
      if (month > 5) {
        firstQuarterMonth = 6
      }
      if (month > 8) {
        firstQuarterMonth = 9
      }

      const firstDay = new Date(date.getFullYear(), firstQuarterMonth, 1)

      this.dateStart = this.formatDate(firstDay)
      this.dateEnd = this.formatDate(new Date())
      this.updateFilter()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.select {
  .course-option {
    padding: 5px;
    overflow: hidden;

    .title {
      overflow: hidden;
    }

    .image-wrapper {
      width: 35px;
      height: 35px;
      min-width: 35px;
      border-bottom: 1px solid $input-border-color;
      border-radius: 5px;

      img {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }

    &.selected {
      .title {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .image-wrapper {
        width: 27px;
        height: 27px;
        min-width: 27px;
      }
    }
  }

  .option {
    padding: 5px;
    overflow: hidden;

    .title {
      overflow: hidden;
    }
    .bottom-line {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $text-muted;
    }
  }
}
</style>

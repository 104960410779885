export function formatTime(number: number) {
  if (!number) {
    return '-'
  }

  const hours = Math.trunc(number)
  const minutes = Math.round(60 * (number - hours))
  return `${hours}ч ${minutes < 10 ? `0${minutes}` : minutes}м`
}

export const colors = [
  '#00bcf2',
  '#fff100',
  '#e81123',
  '#00188f',
  '#009e49',
  '#ff8c00',
  '#ec008c',
  '#bad80a',
  '#68217a',
  '#00b294',
]

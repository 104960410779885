<template>
  <b-overlay :show="pending" rounded="sm">
    <VueApexCharts
      type="line"
      :height="series.length ? 400 : 150"
      :options="chartOptions"
      :series="series"
      @legendClick="removeSeries"
      class="analytics-chart"
    />
  </b-overlay>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { BOverlay } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { colors, formatTime } from '@/helpers/analytics'

export default {
  components: {
    VueApexCharts,
    BOverlay,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 5,
          strokeOpacity: 1,
          strokeColors: colors,
          colors: colors,
        },
        colors: colors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: { showForSingleSeries: true, showForNullSeries: false },
        tooltip: {
          followCursor: true,
          custom: this.tooltipRender,
        },
        xaxis: {
          type: 'category',
        },
        noData: {
          text: 'Выберите значения из таблицы, чтобы отобразить график',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      series: 'analytics/chartSeries',
      pending: 'analytics/chartPending',
    }),
  },
  methods: {
    removeSeries(_, index) {
      this.$store.commit('analytics/removeSeries', index)
    },
    tooltipRender(data) {
      const column = this.series[data.seriesIndex].column
      let value = data.series[data.seriesIndex][data.dataPointIndex]

      if (column === 'answerTime' || column === 'approveTime') {
        value = formatTime(value)
      }

      return `${'<div class="px-1 py-50"><span>'}${value}</span></div>`
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.dark-layout {
  .analytics-chart {
    .apexcharts-text tspan,
    .apexcharts-legend-text {
      color: $theme-dark-body-color !important;
      fill: $theme-dark-body-color !important;
    }
  }
}
</style>